<template>
  <b-card-code title="Modifier Diplôme">
    <b-card-text>
      <span>Veuillez modifier les coordonnées de Diplôme </span>
    </b-card-text>
    <!-- form -->
    <validation-observer
      v-if="diplomas"
      ref="simpleRules"
    >
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Nom"
              label-for="Nom"
            >
              <validation-provider
                #default="{ errors }"
                name="Nom"
                rules="required"
              >
                <b-form-input
                  v-model="diplomas.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Nom"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Diplôme en Arabe"
              label-for="Diplôme en Arabe"
            >
              <validation-provider
                #default="{ errors }"
                name="Diplôme en Arabe"
                rules="required"
              >
                <b-form-input
                  v-model="diplomas.name_arabe"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Diplôme en Arabe"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Etat"
              label-for="Etat"
            >
              <validation-provider
                #default="{ errors }"
                name="state"
                rules="required"
              >
                <v-select
                  v-model="diplomas.category"
                  :clearable="false"
                  :options="categories"
                  placeholder="Statut de l'année"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="editDiplomas"
            >
              Modifier
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText /*  */,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  data() {
    return {
      password: '',
      diplomas: {},
      required,
      categories: ['licence', 'master', 'cycle préparatoire', 'cycle ingénieur'],

    }
  },
  created() {
    this.getDiplomas()
  },
  methods: {
    async getDiplomas() {
      this.load = 'true'
      const { id } = this.$route.params
      const response = await axios.get(`/api/diplomas/detail/${id}`)
      this.diplomas = response.data
      this.load = 'false'
    },
    async editDiplomas() {
      this.$refs.simpleRules.validate().then(async success => {
        const { id } = this.$route.params
        if (success) {
          try {
            await axios
              .put(
                `/api/diplomas/update/${id}/`,
                {
                  name: this.diplomas.name,
                  name_arabe: this.diplomas.name_arabe,
                  category: this.diplomas.category,
                },
              )
            this.$router.push('/diplomas')
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Diplôme Modifié avec succés')
            }, 1000)
          } catch (err) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', err.toString())
            }, 1000)
          }
        }
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
